import React, {useState} from "react"
import {graphql, Link} from "gatsby"
import DefaultHead from "../components/layout/head";

const labels = {
    fr:{
        "title":"Commande",
        "project_type":"Typologie",
        "manufacturer":"Éditeur textile",
        "client":"Client",
        "location":"Ville",
    },
    en:{
        "title":"Product",
        "project_type":"Type",
        "manufacturer":"Textile editor",
        "client":"Customer",
        "location":"City",
    },
}

const PageProjects = ({data,pageContext})=>{
    const projects = data.projects.nodes;
    const {lang, pageViewCount=0, isDesktop=false, isIntro=null} = pageContext;
    const firstLoadDelay = (pageViewCount > 1 && !isIntro) ? 0 : (isDesktop ? 6250 : 2250);
    const firstSpeedDelay = (pageViewCount > 1 && !isIntro) ? 100 : 150;
    const projectSlug = lang==='fr' ? 'projet':'project';
    const columns = [
        {"id": "title"},
        {
            "id": "col2",
            group: [
                {"id": "project_type"},
                {"id": "manufacturer"},
            ]
        },
        {
            "id": "col3",
            group: [
                {"id": "client"},
                {"id": "location"},
            ]
        },
    ]

    const [visitedProjects,setVisitedProject] = useState([]);

    const [hoverId,setHoverId] = useState(false)

    function onEnter(e){
        setHoverId(e.currentTarget.dataset.id);
    }

    function onLeave(e){
        setHoverId(false);
    }

    function onVisitProject(key){
        setVisitedProject(visited=>{
            visited.push(key);
            return visited
        })
    }

    const SpanHeadCell = ({id,key}) => {
        return <span
            key={key}
            role={"cell"}
            className={`column column-${id} column--head column--${id === hoverId ? 'over' : 'out'}`}
        >{labels[lang][id]}{' '}</span>
    }

    const SpanCell = ({id,children,frontmatter,c}) => {
        let label = frontmatter[id];
        if(id === 'client') label = frontmatter.client_short || label; // client_short is optional
        return <span tabIndex={c} key={c} data-id={id} className={`column column-${id}`} onMouseEnter={onEnter}
                     onMouseLeave={onLeave} role={"button"}>{label}{' '}</span>
    }

    return (
        <div className={"projects-table"} role={"table"}>
            <div className={"row row--head"} role={"rowheader"} style={{animationDelay: `${firstLoadDelay}ms`}}>
                {columns.map(({group, id}, k) => {
                    if (group) {
                        return <span key={k} className={`column column-group column-${id} column--head`}>{group.map(({id},i)=>{
                            return <SpanHeadCell {...{key:k,id}} />
                        })}</span>;
                    }else{
                        return <SpanHeadCell {...{key:k,id}} />
                    }
                })}
            </div>
            {projects.map((project,key)=>{
                const {frontmatter} = project
                const {link_rewrite} = frontmatter
                const visited = visitedProjects.includes(key);
                let c = 0;
                return (
                    <Link onClick={e=>onVisitProject(key)} to={`/${lang}/${projectSlug}/${link_rewrite}`} key={key} className={`row ${visited && 'row--visited'}`} role={"row"} style={{animationDelay:`${firstLoadDelay+key*firstSpeedDelay}ms`}}>
                        {columns.map(({group,id},k)=>{
                            if(group){
                                return <span key={k} className={`column column-group column-${id}`}>{group.map(({id},i)=>{
                                    c++;
                                    return <SpanCell {...{id,c,frontmatter}} />
                                })}</span>;
                            }else{
                                c++;
                                return <SpanCell {...{id,c,frontmatter}} />
                            }
                        })}
                    </Link>
                )
            })}
        </div>
    )
}

export default PageProjects

export const query = graphql`
    query ($absPathRegex: String) {
        projects:allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: $absPathRegex}}
            sort: {fields: frontmatter___position, order: ASC}
        ) {
            nodes {
                frontmatter{
                    title
                    id_project
                    link_rewrite
                    location
                    manufacturer
                    project_type
                    client
                    client_short
                }
            }
        }
    }
`

export const Head = DefaultHead