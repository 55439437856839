import {graphql, useStaticQuery} from "gatsby";

export const useMetaTitle = () => {
    const meta_title = useStaticQuery(
        graphql`
            {
                fr: siteMetaKey(key:"title",lang: "fr")
                en: siteMetaKey(key:"title",lang: "en")
            }
        `
    )
    return meta_title
}